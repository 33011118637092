<template>
  <div>
    <v-card-title>Manage Respondents</v-card-title>
    <v-card>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th style="width:30%" scope="col">Status</th>
            <th scope="col">{{respondent.status}}</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </v-card>

    <h3 class="mt-5 mb-5">Responses</h3>

    <v-expansion-panels popout>
      <v-expansion-panel v-for="response in respondent.responses" :key="response._id">
        <v-expansion-panel-header>
          <th class="questionText" colspan="12">
            <v-textarea rows="2" name="question" solo v-model="response.question"></v-textarea>
          </th>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <div>
              <table class="table css-serial">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="displayText">Answer:</td>
                    <td colspan="6">
                      <table>
                        <tr v-for="answer in response.answer" :key="answer._id">
                          <td>
                            <v-text-field name="displayText" solo v-model="answer.answer"></v-text-field>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>



<script>
import RestResource from "@/services/dataServiceBuyer.js";
const service = new RestResource();

export default {
  data() {
    return {
      respondent: []
    };
  },

  mounted() {
    this.loadRespondent();
  },

  methods: {
    loadRespondent() {
      this.$setLoader();
      service.fetchRespondent({ respondentId: this.$route.params.respondentId }).then(r => {
        this.respondent = r.data;
        this.$disableLoader();
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

.bold {
  font-weight: bold;
}
</style>